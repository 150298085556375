/* GLOBAL
---------------------------------------------- */
:root {
  font-family: var(--font);
  color: var(--black);
  -webkit-font-smoothing: antialiased;
}

body,
html {
  -webkit-text-size-adjust: 100%;
}

/* @media only screen and (max-width: 844px) and (orientation: portrait) {
  html {
    font-size: -2.9vmin;
  }
}

@media only screen and (max-width: 844px) and (orientation: landscape) {
  html {
    font-size: 2.1vmin;
  }
} */

html {
  scroll-behavior: smooth;
  background-color: var(--white);
  scroll-padding-top: var(--top-page-padding);
}
body {
  background-color: var(--white);
  overflow-x: hidden;
  overflow-x: clip;
}
.visually-hidden {
  position: absolute !important;
  overflow: hidden;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  border: 0;
  clip: rect(0 0 0 0);
  clip: rect(1px, 1px, 1px, 1px);
  word-wrap: normal !important;
}
.center {
  display: flex;
  justify-content: center !important;
  align-items: center !important;
}
img {
  width: 100%;
  height: auto;
  display: block;
}
.text-align-center {
  text-align: center;
}
.uppercase {
  text-transform: uppercase;
}
p:not(:last-of-type),
.rte ul {
  margin-bottom: 1em;
}
p a {
  color: inherit;
}
.lr-page-padding {
  padding-left: var(--lr-page-padding);
  padding-right: var(--lr-page-padding);
}
/* #smooth-content {
  will-change: transform;
} */
.hidden-on-desktop {
  @media (min-width: 801px) {
    display: none !important;
  }
}
.hidden-on-mobile {
  @media (max-width: 1000px) {
    display: none !important;
  }
}

/* Lazy loading
---------------------------------------------- */

[data-lazy] {
  visibility: hidden;
}

/* .image-wrap {
  background-size: cover;
  min-width: 0;
  background-color: var(--white);
} */
