/* Variables
---------------------------------------------- */
:root {
  --vh: 1;

  --font: TimesNewRoman, Times New Roman, Times, Baskerville, Georgia, serif;

  --small-font-size: 0.933rem; /* 14px */
  --large-font-size: 1.6rem; /* 24px */
  --extra-large-font-size: 2.666rem; /* 40px */

  --white: white;
  --black: black;
  --pink: #fac2dd;

  --lr-page-padding: 15px;

  /* --extra-small: 0.2rem;
  --small: 0.5rem;
  --medium: 1rem;
  --large: 1.8rem;
  --extra-large: 3rem; */

  --top-page-padding: 14px;
  /* 
  --top-page-offset: 95px;
  --grid-gap: 10px; */

  @media (prefers-reduced-motion: no-preference) {
    scroll-behavior: smooth;
  }

  /* Variables for-small-tablet-below
  ---------------------------------------------- */
  @media (max-width: 800px) {
    --lr-page-padding: 10px;

    --small-font-size: 0.933rem; /* 14px */
    --large-font-size: 1.46rem; /* 24px */
    --extra-large-font-size: 2rem; /* 40px */

    --top-page-padding: 10px;

    /* --font-size: 14px;
    --top-page-padding: 10px; */
  }
}
