@import "_reset";
@import "_variables";
@import "_common";
@import "../../node_modules/@splidejs/splide/dist/css/splide.min.css";

/* SITE SPECIFIC
---------------------------------------------- */

html {
  font-size: 0.85vw;
}

@media only screen and (max-width: 1600px) {
  html {
    font-size: 1.0425vw;
  }
}

@media only screen and (max-width: 1200px) {
  html {
    font-size: 1.0425vw;
  }
}

@media only screen and (max-width: 1200px) {
  html {
    font-size: 1.15vw;
  }
}

@media only screen and (max-width: 800px) {
  html {
    /* font-size: 2vw; */
    font-size: 15px;
  }
}

/* @media only screen and (max-width: 500px) {
  html {
    font-size: 3.6vw;
  }
} */

@media (hover: hover) {
  .underlined:hover,
  .rte a:hover {
    text-decoration-color: transparent;
  }
}

.underlined,
.rte a {
  text-decoration: underline;
  text-underline-position: intial;
  text-decoration-thickness: 0.1em;
  transition: text-decoration-color 0.4s ease;
}

@media (hover: hover) {
  .underline-on-hover:hover {
    text-decoration-color: inherit;
  }
}

.underline-on-hover {
  text-decoration: underline;
  text-decoration-color: transparent;
  text-underline-position: intial;
  text-decoration-thickness: 0.1em;
  transition: text-decoration-color 0.4s ease;
}

/* *:focus {
  outline: none;
} */
a:focus,
button:focus {
  outline: none;
}
button:focus-visible,
a:focus-visible {
  outline: 2px solid var(--text-color);
  outline-offset: 3px;
}
/* a:focus-visible {
  background: inherit;
} */
.splide {
  visibility: inherit;
}
.splide.is-initialized,
.splide.is-rendered {
  visibility: inherit;
}
.splide__slide {
  align-self: start;
}
.splide__track {
  height: 100%;
}
/* .splide__track--nav > .splide__list > .splide__slide.is-active,
.splide__track--nav > .splide__list > .splide__slide {
  border: none;
} */
.unstyled-list-parent > *,
.unstyled-list {
  list-style-type: none;
  margin: 0;
  padding: 0;
}
.bold {
  font-weight: 700;
}
.italic {
  font-style: italic;
}
.floating-buttons {
  position: fixed;
  right: calc(var(--lr-page-padding) / 2);
  text-transform: uppercase;
  z-index: 150;
}
.floating-buttons--bottom {
  bottom: calc(var(--lr-page-padding) / 2);
}
.floating-button {
  background-color: var(--light-grey);
  box-shadow: var(--box-shadow);
}
.floating-button__link {
  padding: 0.2rem calc(0.2rem + calc(var(--lr-page-padding) / 2));
  display: block;
}
.secondary-font {
  font-family: var(--heading-font);
  font-weight: 300;
  font-style: normal;
  text-transform: uppercase;
  font-feature-settings: "dlig" on;
}
@media (hover: hover) {
  .clickable:hover {
    cursor: pointer;
  }
}
@media (max-width: 800px) {
}

@media (min-width: 801px) {
}
.contents {
  position: relative;
}
.img-wrapper {
  background-color: var(--pink);
}
::-moz-selection {
  /* Code for Firefox */
  background: var(--pink);
}

::selection {
  background: var(--pink);
}
img.lazy-image {
  opacity: 0;
}
img.lazy-image.loaded {
  opacity: 1;
}
/* HOME
---------------------------------------------- */
.home-template .left-column {
  padding-top: var(--top-page-padding);
}
@media (min-width: 801px) {
  .home-template .left-column__top,
  .home-template .left-column__bottom {
    position: fixed;
  }
}
@media (max-width: 800px) {
  .site-title {
    width: calc(100vw - 4rem - calc(var(--lr-page-padding) * 2));
    font-size: 1.133rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 3.2rem;
  }
}
@media (max-width: 800px) {
  .overlay-navigation {
    text-align: right;
    position: fixed;
    right: var(--lr-page-padding);
    top: var(--top-page-padding);
  }
  .overlay-navigation__list {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 3.2rem;
  }
}

.home-template .right-column {
  position: fixed;
  right: var(--lr-page-padding);
  top: 4.66rem;
  z-index: 2;
}
@media (min-width: 801px) {
  .home-template .right-column {
    left: 37vw;
    top: 0.933rem;
    right: initial;
  }
}

.years__list {
  max-height: calc(100dvh - 4.66rem);
  overflow: scroll;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.years__list::-webkit-scrollbar {
  display: none;
}

@media (min-width: 801px) {
  .years__list {
    column-width: 2.5rem;
    column-gap: 0.5rem; /* Space between columns */
    max-height: calc(100dvh - 2rem);
    max-height: calc(100vh - 2rem);
    overflow: initial;
  }
}
.year {
  font-size: var(--extra-large-font-size);
  display: block;
  break-inside: avoid; /* Prevent items from splitting between columns */
  line-height: 85%;
}
.works {
  width: calc(100vw - 4rem - calc(var(--lr-page-padding) * 3));
  padding-bottom: 2rem;
  padding-top: 0.9rem;
}
@media (min-width: 801px) {
  .works {
    position: absolute;
    width: 40vw;
    right: 8vw;
    top: 5rem;
    padding-bottom: 2rem;
  }
}
.work:not(:last-of-type),
.works__year:not(:last-of-type) {
  margin-bottom: 1.5rem;
}
@media (min-width: 801px) {
  .work:not(:last-of-type),
  .works__year:not(:last-of-type) {
    margin-bottom: 3.2rem;
  }
}
.single-work__wrapper {
  position: relative;
  display: block;
}
.single-work__title {
  text-align: center;
  margin: 0 auto;
  margin-top: 1rem;
}
@media (min-width: 801px) {
  .single-work__title {
    max-width: 70%;
    margin-top: 1.4rem;
  }
}
.single-work__view-overlay {
  color: var(--pink);
  font-size: var(--extra-large-font-size);
  display: none;
  margin: 0;
  position: absolute;
  bottom: -1.5rem;
  left: 50%;
  transform: translate(-50%, 0%);
  z-index: 1;
}
@media (hover: hover) {
  .single-work__wrapper:hover {
    cursor: pointer;
  }
  .single-work__wrapper:hover .single-work__view-overlay {
    display: block;
  }
}

.year__title {
  position: relative;
  z-index: 1;
}
/* .year__title:hover {
  text-decoration: line-through;
  text-decoration-color: var(--pink);
} */

.year__title.highlighted::before,
.year__title:hover::before {
  content: "";
  margin: 0 auto; /* this centers the line to the full width specified */
  position: absolute; /* positioning must be absolute here, and relative positioning must be applied to the parent */
  height: 0.4rem;
  width: 100%;
  background-color: var(--pink);
  bottom: 40%;
  transform: translateY(-40%);
  z-index: -1;
}
.left-column__bottom {
  bottom: 1.1rem;
}
@media (min-width: 801px) {
  .left-column__bottom {
    bottom: 1.45rem;
  }
}
.news {
  width: 17rem;
}
@media (max-width: 800px) {
  .news {
    width: calc(100% - calc(var(--lr-page-padding) * 2));
    background-color: var(--pink);
    padding: var(--lr-page-padding);
    position: fixed;
    bottom: var(--top-page-padding);
    z-index: 4;
  }
}
.news__header {
  display: flex;
  gap: 3rem;
  justify-content: space-between;
  width: 100%;
  /* margin-bottom: 0.2rem; */
}
@media (max-width: 800px) {
  .news__header {
    gap: 2rem;
    justify-content: initial;
  }
}
.news-item__title {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
}
.news-item__date.open::after {
  content: "";
  display: inline-block;
  width: 0.66rem;
  height: 0.66rem;
  border-radius: 50%;
  background-color: var(--pink);
  margin-left: 0.2rem;
  vertical-align: baseline;
}
@media (max-width: 800px) {
  .news-item__date.open::after {
    background-color: var(--white);
    margin-left: 0.15rem;
  }
}
@media (max-width: 800px) {
  .left-column__bottom {
    display: none;
  }
  .active-news {
    display: block;
  }
  .news__counter {
    margin-left: auto;
  }
  .modal-close--news {
    position: initial !important;
    margin-left: 1rem;
  }
}

/* WORK
---------------------------------------------- */
.work-template .left-column {
  padding-top: var(--top-page-padding);
}
.work-template .left-column__top {
  position: fixed;
  max-width: calc(25vw - var(--lr-page-padding) - 3.5rem - 1.53rem);
}
@media (max-width: 800px) {
  .work-template .left-column__top {
    padding-top: 1rem;
    position: initial;
    max-width: 100%;
  }
}
@media (max-width: 800px) {
  .work-close {
    position: fixed;
    top: var(--top-page-padding);
    z-index: 2;
  }
}

.work-template .right-column {
  padding-top: 2.2rem;
  padding-left: 25vw;
}
@media (max-width: 800px) {
  .work-template .right-column {
    padding: 0;
    padding-top: 1rem;
  }
}
.work-title {
  margin-top: 0.9rem;
  font-size: var(--large-font-size);
}
.work-link {
  margin-top: 0.6rem;
  margin-right: 0.6rem;
  display: inline-block;
}
.description-wrapper {
  margin-bottom: 1.53rem;
}
.description {
  max-width: 40vw;
  text-align: justify;
  display: -webkit-box;
  -webkit-line-clamp: 10; /* Limits to 10 lines */
  -webkit-box-orient: vertical;
  overflow: hidden;
  position: relative;
}
@media (max-width: 800px) {
  .description {
    -webkit-line-clamp: 8; /* Limits to 10 lines */
    max-width: 100%;
  }
}
.description-wrapper.expanded .description {
  display: block;
  -webkit-line-clamp: unset;
  overflow: visible;
}
.read-more {
  display: block;
  margin-top: 1em;
  text-decoration: underline;
}
.blocks {
  padding-bottom: 2rem;
  counter-reset: section;
  position: relative;
}
@media (min-width: 801px) {
  .block::before {
    counter-increment: section;
    content: counter(section) ". ";
    font-size: var(--extra-large-font-size);
    line-height: 99.823%;
    font-weight: 700;
    font-style: italic;
    position: absolute;
    left: -3.5rem;
    text-align: right;
  }
}
.block:not(:last-of-type) {
  margin-bottom: 1.53rem;
}
.block-grid {
  display: grid;
  grid-template-columns: 40vw auto;
  gap: 1.53rem;
}
@media (max-width: 800px) {
  .block-grid {
    grid-template-columns: 1fr;
    gap: 1rem;
  }
}
.work-caption {
  font-size: var(--extra-large-font-size);
  line-height: 99.823%;
}
.work-caption p {
  display: inline;
}
.vid-wrapper {
  --video--width: 1296;
  --video--height: 730;

  position: relative;
  padding-bottom: calc(
    var(--video--height) / var(--video--width) * 100%
  ); /* 41.66666667% */
  overflow: hidden;
  max-width: 100%;
  background: black;
}
.vid-wrapper iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.wallpaper-image {
  position: fixed;
  top: 0;
  z-index: -1;
}
.wallpaper-image img {
  height: 100svh;
  height: 100vh;
  width: 100vw;
  object-fit: cover;
}
@media (min-width: 801px) {
  .work-template--wallpaper {
    background-color: transparent;
  }
  .work-template--wallpaper .description {
    margin: -1rem;
    padding: 1rem;
    background-color: var(--white);
  }
  .work-template--wallpaper .left-column__top {
    max-width: calc(25vw - var(--lr-page-padding) - 3.5rem);
    margin: -1rem;
    padding: 1rem;
    background-color: var(--white);
  }
}
@media (max-width: 801px) {
  .work-template--wallpaper .description {
    padding-bottom: 1rem;
  }
  .work-template--wallpaper .contents {
    margin-bottom: 100dvh;
    margin-bottom: 100vh;
  }
}
/* MODALS
---------------------------------------------- */
.modal {
  background-color: var(--pink);
  position: fixed;
  top: 0;
  padding: 0.9rem;
  z-index: 1;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
.modal::-webkit-scrollbar {
  display: none;
}
.modal ::-moz-selection {
  background: var(--white); /* Code for Firefox */
}
.modal ::selection {
  background: var(--white);
}
@media (max-width: 800px) {
  .modal {
    padding: var(--top-page-padding) var(--lr-page-padding);
  }
}
.button-wrapper {
  position: sticky;
  top: 0;
  display: flex;
  align-items: end;
  justify-content: end;
  justify-items: end;
  width: 100%;
}
/* About Modal */
.about-modal {
  width: 40vw;
  height: 100dvh;
  height: 100vh;
  overflow: scroll;
  padding-bottom: 2rem;
  left: -40vw;
  transition: left 0.35s ease-out;
}
.about-modal.active {
  left: 0;
}
.about-modal__content {
  padding-right: 3.5rem;
}
.about-section__heading {
  margin-top: 2rem;
  margin-bottom: 1em;
}
.about-section__text,
.cv-grid__list-wrapper li {
  text-align: justify;
}
.cv-grid {
  display: grid;
  grid-template-columns: 6rem auto;
}
.cv-grid:not(:last-of-type) {
  margin-bottom: 1em;
}
@media (min-width: 801px) {
  .about-modal .button-wrapper {
    display: initial;
  }
}
@media (max-width: 800px) {
  .about-modal__content {
    padding-right: 0rem;
  }
  .about-modal {
    width: 100vw;
    left: -100vw;
    transition: left 0.35s ease-out;
    z-index: 10;
  }
}

/* Texts Modal */
.texts-modal {
  right: -60vw;
  width: 60vw;
  height: 100dvh;
  height: 100vh;
  overflow: scroll;
  transition: right 0.35s ease-out;
}
@media (max-width: 800px) {
  .texts-modal {
    right: initial;
    left: -100vw;
    width: 100vw;
    transition: left 0.35s ease-out;
    z-index: 10;
    padding-bottom: 2rem;
  }
}
.texts-modal.active {
  right: 0;
}
@media (max-width: 800px) {
  .texts-modal.active {
    right: initial;
    left: 0;
  }
}
.texts-modal__content {
  padding: 5rem;
  padding-left: 9rem;
}
@media (max-width: 800px) {
  .texts-modal__content {
    padding: initial;
    margin-top: 2rem;
  }
}
.texts-modal .modal-close {
  text-align: right;
  margin-left: auto;
}
.texts-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 4rem;
}
@media (max-width: 800px) {
  .texts-grid {
    grid-template-columns: 1fr;
    gap: 2em;
  }
}

@media (max-width: 800px) {
  .text {
    max-width: 85%;
  }
}
/* Contact Modal */
.contact-modal {
  margin: 0;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: none;
  z-index: 2;
}
@media (max-width: 800px) {
  .contact-modal {
    margin: 0;
    top: 0;
    left: -100vw;
    transform: initial;
    display: block;
    z-index: 4;
    width: 100vw;
    height: 100dvh;
    height: 100vh;
    transition: left 0.35s ease-out;
  }
}
.contact-modal.active {
  display: block;
}
@media (max-width: 800px) {
  .contact-modal.active {
    left: 0vw;
  }
}
.contact-modal__content {
  padding: 3.8rem;
}
.email__link,
.gallery {
  font-size: var(--extra-large-font-size);
  text-align: center;
}

@media (min-width: 801px) {
  .contact-modal .button-wrapper {
    display: initial;
  }
}

@media (max-width: 800px) {
  .contact-modal__content {
    padding: 1rem 0;
    position: absolute;
    margin: 0;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .email__link,
  .gallery {
    font-size: var(--large-font-size);
  }
}
